﻿<script lang="ts">
    import {Column, Title, Button} from 'Components/UI';
    import {translations} from 'Assets/i18n/en-US';
    import {getContext} from 'svelte';
    import DependencyContainer from 'Lib/DependencyContainer';
    import UserService from 'Lib/Services/UserService';
    import UserListLoginProvider from 'Lib/Services/UserListLoginProvider';
    import {applicationContextKey} from 'LoginFrame/LoginDependencies';
    
    const applicationContext = getContext<DependencyContainer>(applicationContextKey);
    const userService = applicationContext.get<UserService>(UserService);

    const translationContext = translations.pages.login;
    export let title: string = '';

    let username: string;
    let password: string;
    let didLoginFail: boolean;

    async function handleLoginAsync(): Promise<void> {
        await userService.setProvider(UserListLoginProvider.staticKey);
        didLoginFail = !(await userService.loginAsync(false, {username, password}));
        if (!didLoginFail) {
            username = '';
        }
        password = '';
    }

</script>
<form on:submit|preventDefault={handleLoginAsync}>
    <Column padding="1rem" horizontalAlign="stretch" width="100%">
        <Column verticalAlign="center">
            <label for="usernameField">{translationContext.fields.username}</label>
            <input
                    id="usernameField"
                    autofocus
                    minlength="1"
                    placeholder={translationContext.fields.username}
                    required
                    type="text"
                    bind:value={username}/>
        </Column>
        <Column verticalAlign="center">
            <label for="passwordField">{translationContext.fields.password}</label>
            <input
                    id="passwordField"
                    minlength="1"
                    placeholder={translationContext.fields.password}
                    required
                    type="password"
                    bind:value={password}/>
        </Column>
        <Button
                margin="1rem 0"
                alignSelf="center"
                type="submit">
            {translationContext.loginAction}
        </Button>
    </Column>
</form>
