﻿import AzureActiveDirectoryLogin from 'Pages/LoginPage/LoginProviders/AzureActiveDirectoryLogin.svelte';
import UserListLogin from 'Pages/LoginPage/LoginProviders/UserListLogin.svelte';
import AzureActiveDirectoryLoginProvider from 'Lib/Services/AzureActiveDirectoryLoginProvider';
import UserListLoginProvider from 'Lib/Services/UserListLoginProvider';

const components = new Map([
    [AzureActiveDirectoryLoginProvider.staticKey, AzureActiveDirectoryLogin],
    [UserListLoginProvider.staticKey, UserListLogin],
]);

export function getLoginComponentFor(provider: string) {
    const component = components.get(provider);
    if(!component) throw new Error(`Component for provider '${provider}' not found`);
    return component;
}
