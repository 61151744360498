﻿<script lang="ts">
    import {getContext} from 'svelte';
    import DependencyContainer from 'Lib/DependencyContainer';
    import {applicationContextKey} from 'PublicationDependencies';
    import adLoginButton from 'Assets/ad-login.svg';
    import UserService from 'Lib/Services/UserService';
    import AzureActiveDirectoryLoginProvider from 'Lib/Services/AzureActiveDirectoryLoginProvider';

    const applicationContext = getContext<DependencyContainer>(applicationContextKey);
    const userService = applicationContext.get<UserService>(UserService);

    async function login(): Promise<boolean> {
        await userService.setProvider(AzureActiveDirectoryLoginProvider.staticKey);
        return userService.loginAsync();
    }
</script>

<img src={adLoginButton} alt="Login with Microsoft" on:click={login}>

<style>
    img {
        height: 41px; /* Microsoft recommended height */
        cursor: pointer;
        margin: 1rem;
    }

    img:active {
        filter: brightness(80%);
    }
</style>
