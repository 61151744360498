﻿<script lang="ts">
    import { Notification } from 'Components/UI';

    import { NotificationLevel } from 'Lib/Models/NotificationLevel';
    import type NotificationService from 'Lib/Services/NotificationService';
    import type NotificationModel from 'Lib/Models/NotificationModel';

    export let notificationService: NotificationService;
    const AUTO_HIDE_TIMEOUT = 5000;

    function onNewNotification(notification: NotificationModel): void {
        notifications.push(notification);
        setTimeout(() => onNotificationRemove(notification), AUTO_HIDE_TIMEOUT);

        notifications = notifications;
    }

    function onNotificationRemove(notification: NotificationModel): void {
        notifications.splice(
            notifications.findIndex(item => item.id === notification.id),
            1,
        );

        notifications = notifications;
    }

    notificationService.addNotificationCallback(onNewNotification);
    let notifications: NotificationModel[] = [];
</script>

<div>
    {#each notifications as notification}
        {@const notificationColor =
            notification.level === NotificationLevel.Success
                ? 'var(--notification-positive-color)'
                : notification.level === NotificationLevel.Error
                ? 'var(--notification-negative-color)'
                : 'teal'}
        <Notification
            backgroundColor={notificationColor}
            on:close={() => onNotificationRemove(notification)}>
            {#if notification.level !== NotificationLevel.Success}
                <span
                    class="mdi"
                    class:mdi-alert-circle-outline={notification.level === NotificationLevel.Error}
                    class:mdi-check-outline={notification.level === NotificationLevel.Info} />
            {/if}
            <p class="text">{notification.message}</p>
        </Notification>
    {/each}
</div>

<style>
    div {
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 18px;

        align-items: end;
        z-index: 9999;
    }

    .text {
        text-overflow: ellipsis;
        overflow: hidden;
        height: 100%;
    }

    .mdi {
        font-size: 24px;
        margin-right: 8px;
    }
</style>
